import { useTheme } from '@emotion/react';
import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { useTimer } from 'react-timer-hook';
import moment from 'moment';
import RedRoubleSVG from '../../public/img/redRouble.svg';
import WhenSVG from '../../public/img/when.svg';
import LocationSVG from '../../public/img/location.svg';
import ViewsSVG from '../../public/img/views2.svg';
import RatingSVG from '../../public/img/star-outline.svg';
import PushkinSVG from '../../public/img/pushkin.svg';
import {
  countdownInit,
  formatTimer,
  getBenefit,
  getTitleForTimer,
  splitPriceByThree,
} from '../../utils';
import { eventTypes } from '../../constants/events';
import {
  DescriptionItem,
  LabelFeatureListItem,
  ActionLabelItem,
} from './styled';
import { Picture } from '../picture/picture';
import { Link } from '../styled/Link';
import { DEVICE_SCREENS_MAX_STRING } from '../../lib/consts';
import { City } from '../../models';
import FavoriteButton from '../favorite/FavoriteButton';
import FavoriteButtonCardWrapper from '../favorite/FavoriteButtonCardWrapper';
import { FavoriteKinds } from '../../models/favorite';
import { ImageSources } from '../../models/image';
import { Action } from '../../models/action';

export function PostCard({
  item,
  currentCity,
  catalog,
  scroll,
  withOutWrapper,
  href,
}: {
  item: Action;
  currentCity: City;
  catalog?: boolean;
  scroll?: boolean;
  withOutWrapper?: boolean;
  href?: string | null;
}) {
  const theme = useTheme();

  const [timerData, setTimerData] = useState<{
    showTimer: boolean;
    startDate: any;
    endDate: any;
    title: string | null;
  }>({
    showTimer: false,
    startDate: null,
    endDate: null,
    title: null,
  });

  const { seconds, minutes, hours, days, restart } = useTimer({
    expiryTimestamp: timerData.endDate
      ? DateTime.fromISO(timerData.endDate, { zone: 'utc' }).valueOf()
      : new Date().valueOf(),
    onExpire: () => console.warn('onExpire called'),
  } as any);

  const [progressValue, setProgressValue] = useState(0);

  useEffect(() => {
    const newTimerData = {
      showTimer: false,
      startDate: null,
      endDate: null,
      title: getTitleForTimer(item.countdown_type),
    };
    let dataPrepareForTimer = null;
    countdownInit(
      [DateTime.fromISO(item.countdown_datetime)],
      item.countdown_type,
      (countdown) => {
        dataPrepareForTimer = countdown;
      }
    );

    if (dataPrepareForTimer) {
      newTimerData.showTimer = true;
      newTimerData.endDate =
        moment().valueOf() + (dataPrepareForTimer as any).date;
      newTimerData.startDate = moment(
        moment().valueOf() + (dataPrepareForTimer as any).date
      ).subtract(7, 'day') as any;
    }

    if (newTimerData.showTimer) {
      const a =
        (Number(moment().valueOf()) -
          Number((newTimerData as any).startDate.valueOf())) *
        100;
      const b =
        Number(moment(newTimerData.endDate).valueOf()) -
        Number((newTimerData as any).startDate.valueOf());
      const newProgressVal = Math.round(a / b);
      setProgressValue(newProgressVal);
      restart((newTimerData as any).endDate);
    }

    setTimerData(newTimerData);
  }, [item]);

  return withOutWrapper ? (
    <div
      // action-card
      itemScope
      itemType='https://schema.org/Event'
      css={{
        height: '100%',
        borderRadius: '4px',
        backgroundColor: '#fff',
        position: 'relative',
        top: 0,
        width: '100%',
        transition: 'all 0.3s ease',
        display: 'flex',
        flexDirection: 'column',
        '&:hover': {
          transform: 'translateY(-4px)',
          boxShadow: theme.constants.boxShadow,
        },
      }}
    >
      <Link
        // action-card__image-wrapper
        itemProp='url'
        href={href || ['', currentCity.url, 'actions', item.slug, ''].join('/')}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        css={{
          height: 0,
          width: '100%',
          paddingBottom: '75%',
          position: 'relative',
          display: 'block',
          '&:hover': {
            color: theme.colors.black,
          },
        }}
      >
        {item.image && (
          <div
            // action-card__image
            css={{
              borderRadius: '4px',
              position: 'absolute',
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              overflow: 'hidden',
            }}
          >
            <Picture src={item.image} alt={item.title} is360 />
          </div>
        )}
        {item.countdown_type &&
          item.countdown_type !== 'unset' &&
          timerData.showTimer && (
            <div
              css={{
                width: '100%',
                background: 'rgba(0, 0, 0, 0.7)',
                color: '#fff',
                padding: '6px 0',
                zIndex: 10,
                position: 'absolute',
                left: 0,
                bottom: 0,
              }}
            >
              <div
                css={{
                  width: '90%',
                  margin: '0 auto',
                }}
              >
                <p css={{ textAlign: 'center', fontSize: 13 }}>
                  {timerData.title} {formatTimer(days, hours, minutes, seconds)}
                </p>
                <div
                  css={{
                    width: '100%',
                    height: 3,
                    background: '#fff',
                    borderRadius: 2,
                    marginTop: 5,
                  }}
                >
                  <div
                    css={{
                      height: '100%',
                      width: `${progressValue}%`,
                      background: 'rgb(244, 67, 54)',
                      borderRadius: 2,
                      marginLeft: -1,
                    }}
                  />
                </div>
              </div>
            </div>
          )}
      </Link>
      <ul
        // action-card__label-container-sub
        css={{
          padding: 0,
          margin: 0,
          position: 'absolute',
          top: '-7px',
          left: '8px',
          right: '8px',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
        }}
      >
        {item.best_reviews && (
          <LabelFeatureListItem
            text='лучшие отзывы'
            title='Пользователи высоко оценивают мероприятие'
          />
        )}
        {item.hit && (
          <LabelFeatureListItem
            text='хит'
            title='Пользователи часто просматривают это мероприятие'
          />
        )}
        {item.first_time && (
          <LabelFeatureListItem
            text='впервые'
            title='Событие размещено на сайте впервые'
          />
        )}
        {item.premiere && (
          <LabelFeatureListItem
            text='премьера'
            title='Премьерные показы мероприятия'
          />
        )}
        {item.prepayment && (
          <LabelFeatureListItem
            text='предоплата'
            title='Онлайн вы вносите только предоплату'
          />
        )}
      </ul>
      <FavoriteButtonCardWrapper>
        <FavoriteButton
          kind={FavoriteKinds.event}
          id={item.remote_id}
          size='small'
          variant='red-outline'
        />
      </FavoriteButtonCardWrapper>
      <div
        // action-card__label-block
        css={{
          fontFamily: 'Golos',
          position: 'absolute',
          top: '30px',
          left: 0,
          zIndex: 1,
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {!item.instead_price &&
          !item.filter_price &&
          item.type !== eventTypes.restaurant && (
            <ActionLabelItem
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              css={{
                borderRadius: item.pushkin_card_payment ? '0' : '0 4px 4px 0',
                backgroundColor: theme.colors.red,
                background: 'rgba(0, 102, 204, 0.80)',
              }}
            >
              бесплатно
            </ActionLabelItem>
          )}
        {!!item.discount && (
          <ActionLabelItem
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            css={{
              borderRadius: item.pushkin_card_payment ? '0' : '0 4px 4px 0',
              backgroundColor: 'rgba(244, 67, 54, 0.80)',
            }}
          >
            −{item.discount}%&nbsp;скидка
          </ActionLabelItem>
        )}
        {item.pushkin_card_payment && (
          <div
            css={{
              width: 34,
              height: 34,
              background: '#fff',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderTopRightRadius: 4,
              borderBottomRightRadius: 4,
              opacity: 0.8,
            }}
          >
            <PushkinSVG css={{ width: 24, height: 24 }} />
          </div>
        )}
      </div>
      <div
        // action-card__info
        css={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: '20px 20px 40px',
        }}
      >
        <div
          // action-card__description
          css={{
            color: '#000',
            fontFamily: 'Golos',
            fontWeight: 400,
            fontSize: '12px',
            '&>div': {
              marginBottom: '6px',
            },
          }}
        >
          {item.filter_price && item.type !== eventTypes.restaurant ? (
            <DescriptionItem
              itemProp='offers'
              itemScope
              itemType='https://schema.org/Offer'
            >
              <RedRoubleSVG css={{ width: '16px', height: '16px' }} />
              {!!item.instead_price && !!item.filter_price && (
                <span
                  title={`Ваша экономия ${splitPriceByThree(
                    getBenefit(item.instead_price, item.filter_price)
                  )}₽`}
                >
                  <span
                    css={{
                      color: theme.colors.textSecondary,
                      opacity: '0.6',
                      textDecoration: 'line-through',
                    }}
                  >
                    {splitPriceByThree(item.instead_price)}&nbsp;
                  </span>{' '}
                  <span itemProp='price'>
                    {splitPriceByThree(item.filter_price)}{' '}
                  </span>
                  {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                  {/* @ts-ignore */}
                  <span itemProp='priceCurrency' content='RUB'>
                    {' '}
                    рублей
                  </span>
                </span>
              )}
              {!!item.filter_price && !item.instead_price && (
                <span>
                  {item.display_price && item.display_price.indexOf('от') !== -1
                    ? 'от '
                    : ''}
                  {splitPriceByThree(item.filter_price)} рублей
                </span>
              )}
            </DescriptionItem>
          ) : (
            ''
          )}
          {item.type !== eventTypes.restaurant && !!item.dates_txt && (
            <DescriptionItem
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              css={{
                '& span:not(:last-child):after': {
                  content: '", "',
                },
              }}
            >
              {item.has_further_dates && item.dates_txt ? (
                <>
                  <WhenSVG css={{ width: '16px', height: '16px' }} />
                  <span>{item.dates_txt}</span>
                </>
              ) : (
                <span
                  css={{
                    display: 'flex',
                    gap: 8,
                  }}
                >
                  {item.date_unknown && (
                    <>
                      <WhenSVG css={{ width: '16px', height: '16px' }} />
                      <p>Дата уточняется</p>
                    </>
                  )}
                </span>
              )}
            </DescriptionItem>
          )}
          {item.place_title && (
            <DescriptionItem itemProp='location'>
              <LocationSVG
                fill={theme.colors.red}
                css={{ width: '16px', height: '16px' }}
              />
              <span itemProp='name'>{item.place_title}</span>
            </DescriptionItem>
          )}
        </div>
        <Link
          // action-card__title
          itemProp='url'
          href={
            href || ['', currentCity.url, 'actions', item.slug, ''].join('/')
          }
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          css={{
            fontFamily: 'Montserrat',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: '22px',
            letterSpacing: '0em',
            color: theme.colors.black,
            textDecoration: 'none',
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            '&:hover': {
              color: theme.colors.black,
            },
          }}
        >
          <span itemProp='name'>{item.title}</span>
        </Link>
        <div
          css={{
            position: 'absolute',
            bottom: '12px',
            right: '20px',
            left: '20px',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            color: theme.colors.grayStrong,
            fontSize: '12px',
            fontWeight: 500,
          }}
        >
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              marginRight: '18px',
              height: '16px',
              fontFamily: 'Golos',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '16px',
              letterSpacing: '0.01em',
              svg: {
                flex: '0 0 auto',
                marginRight: '5px',
              },
            }}
          >
            <ViewsSVG
              fill={theme.colors.grayStrong}
              css={{ width: '16px', height: '16px' }}
            />
            <span>{item.views}</span>
          </div>
          {item.comments_avg_rating > 0 && (
            <div
              css={{
                display: 'flex',
                alignItems: 'center',
                marginRight: '18px',
                height: '16px',
                fontFamily: 'Golos',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '16px',
                letterSpacing: '0.01em',
                svg: {
                  flex: '0 0 auto',
                  marginRight: '5px',
                },
              }}
            >
              <RatingSVG css={{ width: '16px', height: '16px' }} />
              <span>{item.comments_avg_rating.toFixed(1)}</span>
            </div>
          )}
          {item.age_category && (
            <div
              css={{
                fontFamily: 'Golos',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '16px',
                letterSpacing: '0.01em',
                marginLeft: 'auto',
                color: theme.colors.grayStrong,
              }}
            >
              {item.age_category}
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div
      // actions__item actions__item_scroll
      itemScope
      itemType='https://schema.org/Event'
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      css={{
        ...theme.mixins.col(4),
        marginBottom: 16,
        [DEVICE_SCREENS_MAX_STRING.xl]: {
          ...theme.mixins.col(3),
        },
        [DEVICE_SCREENS_MAX_STRING.md]: {
          ...theme.mixins.col(2),
        },
        ...(scroll
          ? {
              [DEVICE_SCREENS_MAX_STRING.sm]: {
                ...theme.mixins.col(1.3),
                marginBottom: 0,
              },
            }
          : {}),
        ...(catalog
          ? {
              ...theme.mixins.col(3),
              [DEVICE_SCREENS_MAX_STRING.md]: {
                ...theme.mixins.col(2),
              },
              [DEVICE_SCREENS_MAX_STRING.sm]: {
                ...theme.mixins.col(1),
              },
            }
          : {}),
      }}
      className='actions__item'
    >
      <div css={{ display: 'flex', height: '100%' }}>
        <div
          // action-card
          css={{
            height: '100%',
            borderRadius: '4px',
            backgroundColor: '#fff',
            position: 'relative',
            top: 0,
            width: '100%',
            transition: 'all 0.3s ease',
            display: 'flex',
            flexDirection: 'column',
            '&:hover': {
              transform: 'translateY(-4px)',
              boxShadow: theme.constants.boxShadow,
            },
          }}
        >
          <Link
            // action-card__image-wrapper
            itemProp='url'
            href={
              href || ['', currentCity.url, 'actions', item.slug, ''].join('/')
            }
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            css={{
              height: 0,
              width: '100%',
              paddingBottom: '75%',
              position: 'relative',
              display: 'block',
              '&:hover': {
                color: theme.colors.black,
              },
            }}
          >
            {(item.photo_preview || item.image) && (
              <div
                // action-card__image
                css={{
                  borderRadius: '4px',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  bottom: 0,
                  right: 0,
                  overflow: 'hidden',
                }}
              >
                <Picture
                  src={item.photo_preview || (item.image as ImageSources)}
                  alt={item.title}
                  is360
                />
              </div>
            )}
            {item.countdown_type &&
              item.countdown_type !== 'unset' &&
              timerData.showTimer && (
                <div
                  css={{
                    width: '100%',
                    background: 'rgba(0, 0, 0, 0.7)',
                    color: '#fff',
                    padding: '6px 0',
                    zIndex: 10,
                    position: 'absolute',
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <div
                    css={{
                      width: '90%',
                      margin: '0 auto',
                    }}
                  >
                    <p css={{ textAlign: 'center', fontSize: 13 }}>
                      {timerData.title}{' '}
                      {formatTimer(days, hours, minutes, seconds)}
                    </p>
                    <div
                      css={{
                        width: '100%',
                        height: 3,
                        background: '#fff',
                        borderRadius: 2,
                        marginTop: 5,
                      }}
                    >
                      <div
                        css={{
                          height: '100%',
                          width: `${progressValue}%`,
                          background: 'rgb(244, 67, 54)',
                          borderRadius: 2,
                          marginLeft: -1,
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
          </Link>
          <ul
            // action-card__label-container-sub
            css={{
              padding: 0,
              margin: 0,
              position: 'absolute',
              top: '-7px',
              left: '8px',
              right: '8px',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
            }}
          >
            {item.best_reviews && (
              <LabelFeatureListItem
                text='лучшие отзывы'
                title='Пользователи высоко оценивают мероприятие'
              />
            )}
            {item.hit && (
              <LabelFeatureListItem
                text='хит'
                title='Пользователи часто просматривают это мероприятие'
              />
            )}
            {item.first_time && (
              <LabelFeatureListItem
                text='впервые'
                title='Событие размещено на сайте впервые'
              />
            )}
            {item.premiere && (
              <LabelFeatureListItem
                text='премьера'
                title='Премьерные показы мероприятия'
              />
            )}
            {item.prepayment && (
              <LabelFeatureListItem
                text='предоплата'
                title='Онлайн вы вносите только предоплату'
              />
            )}
          </ul>
          <FavoriteButtonCardWrapper>
            <FavoriteButton
              kind={FavoriteKinds.event}
              id={item.remote_id}
              size='small'
              variant='red-outline'
            />
          </FavoriteButtonCardWrapper>
          <div
            // action-card__label-block

            css={{
              fontFamily: 'Golos',
              position: 'absolute',
              top: '30px',
              left: 0,
              zIndex: 1,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            {!item.instead_price &&
              !item.filter_price &&
              item.type !== eventTypes.restaurant && (
                <ActionLabelItem
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  css={{
                    borderRadius: item.pushkin_card_payment
                      ? '0'
                      : '0 4px 4px 0',
                    backgroundColor: theme.colors.red,
                    background: 'rgba(0, 102, 204, 0.80)',
                  }}
                >
                  бесплатно
                </ActionLabelItem>
              )}
            {!!item.discount && (
              <ActionLabelItem
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                css={{
                  borderRadius: item.pushkin_card_payment ? '0' : '0 4px 4px 0',
                  backgroundColor: 'rgba(244, 67, 54, 0.80)',
                }}
              >
                −{item.discount}%&nbsp;скидка
              </ActionLabelItem>
            )}
            {item.pushkin_card_payment && (
              <div
                css={{
                  width: 34,
                  height: 34,
                  background: '#fff',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderTopRightRadius: 4,
                  borderBottomRightRadius: 4,
                  opacity: 0.8,
                }}
              >
                <PushkinSVG css={{ width: 24, height: 24 }} />
              </div>
            )}
          </div>
          <div
            // action-card__info
            css={{
              display: 'flex',
              flex: 1,
              flexDirection: 'column',
              justifyContent: 'space-between',
              padding: '20px 20px 40px',
            }}
          >
            <div
              // action-card__description
              css={{
                color: '#000',
                fontFamily: 'Golos',
                fontWeight: 400,
                fontSize: '12px',
                '&>div': {
                  marginBottom: '6px',
                },
              }}
            >
              {item.filter_price && item.type !== eventTypes.restaurant ? (
                <DescriptionItem
                  itemProp='offers'
                  itemScope
                  itemType='https://schema.org/Offer'
                >
                  <RedRoubleSVG css={{ width: '16px', height: '16px' }} />
                  {!!item.instead_price && !!item.filter_price && (
                    <span
                      title={`Ваша экономия ${splitPriceByThree(
                        getBenefit(item.instead_price, item.filter_price)
                      )}₽`}
                    >
                      <span
                        css={{
                          color: theme.colors.textSecondary,
                          opacity: '0.6',
                          textDecoration: 'line-through',
                        }}
                      >
                        {splitPriceByThree(item.instead_price)}&nbsp;
                      </span>{' '}
                      <span itemProp='price'>
                        {splitPriceByThree(item.filter_price)}
                      </span>
                      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                      {/* @ts-ignore */}
                      <span itemProp='priceCurrency' content='RUB'>
                        {' '}
                        рублей
                      </span>
                    </span>
                  )}
                  {!!item.filter_price && !item.instead_price && (
                    <span>
                      {item.display_price &&
                      item.display_price.indexOf('от') !== -1
                        ? 'от '
                        : ''}
                      {splitPriceByThree(item.filter_price)} рублей
                    </span>
                  )}
                </DescriptionItem>
              ) : (
                ''
              )}
              {item.type !== eventTypes.restaurant && !!item.dates_txt && (
                <DescriptionItem
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  css={{
                    '& span:not(:last-child):after': {
                      content: '", "',
                    },
                  }}
                >
                  {item.has_further_dates && item.dates_txt ? (
                    <>
                      <WhenSVG css={{ width: '16px', height: '16px' }} />
                      <span>{item.dates_txt}</span>
                    </>
                  ) : (
                    <span
                      css={{
                        display: 'flex',
                        gap: 8,
                      }}
                    >
                      {item.date_unknown && (
                        <>
                          <WhenSVG css={{ width: '16px', height: '16px' }} />
                          <p>Дата уточняется</p>
                        </>
                      )}
                    </span>
                  )}
                </DescriptionItem>
              )}
              {item.place_title && (
                <DescriptionItem itemProp='location'>
                  <LocationSVG
                    fill={theme.colors.red}
                    css={{ width: '16px', height: '16px' }}
                  />
                  <span itemProp='name'>{item.place_title}</span>
                </DescriptionItem>
              )}
            </div>
            <Link
              // action-card__title
              itemProp='url'
              href={
                href ||
                ['', currentCity.url, 'actions', item.slug, ''].join('/')
              }
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              css={{
                fontFamily: 'Montserrat',
                fontSize: '18px',
                fontStyle: 'normal',
                fontWeight: 700,
                lineHeight: '22px',
                letterSpacing: '0em',
                color: theme.colors.black,
                textDecoration: 'none',
                display: 'flex',
                alignItems: 'center',
                flex: 1,
                '&:hover': {
                  color: theme.colors.black,
                },
              }}
            >
              <span itemProp='name'>{item.title}</span>
            </Link>
            <div
              css={{
                position: 'absolute',
                bottom: '12px',
                right: '20px',
                left: '20px',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                color: theme.colors.grayStrong,
                fontSize: '12px',
                fontWeight: 500,
              }}
            >
              <div
                css={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: '18px',
                  height: '16px',
                  fontFamily: 'Golos',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '16px',
                  letterSpacing: '0.01em',
                  svg: {
                    flex: '0 0 auto',
                    marginRight: '5px',
                  },
                }}
              >
                <ViewsSVG
                  fill={theme.colors.grayStrong}
                  css={{ width: '16px', height: '16px' }}
                />
                <span>{item.views}</span>
              </div>
              {item.comments_avg_rating > 0 && (
                <div
                  css={{
                    display: 'flex',
                    alignItems: 'center',
                    marginRight: '18px',
                    height: '16px',
                    fontFamily: 'Golos',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '16px',
                    letterSpacing: '0.01em',
                    svg: {
                      flex: '0 0 auto',
                      marginRight: '5px',
                    },
                  }}
                >
                  <RatingSVG css={{ width: '16px', height: '16px' }} />
                  <span>{item.comments_avg_rating.toFixed(1)}</span>
                </div>
              )}
              {item.age_category && (
                <div
                  css={{
                    fontFamily: 'Golos',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '16px',
                    letterSpacing: '0.01em',
                    marginLeft: 'auto',
                    color: theme.colors.grayStrong,
                  }}
                >
                  {item.age_category}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
