import { useTheme } from '@emotion/react';
import { ReactNode } from 'react';

export function LabelFeatureListItem({
  text,
  title,
  ...restProps
}: {
  text: string;
  title: string;
}) {
  const theme = useTheme();
  return (
    <li
      css={{
        display: 'block',
        textAlign: 'center',
        marginRight: '6px',
        background: theme.colors.red,
        borderRadius: '2px',
        fontFamily: 'Golos',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '13px',
        lineHeight: '100%',
        color: theme.colors.white,
        padding: '4px 10px',
      }}
      title={title}
      {...restProps}
    >
      {text}
    </li>
  );
}

export function ActionLabelItem({
  children,
  ...restProps
}: {
  children: ReactNode;
}) {
  const theme = useTheme();
  return (
    <div
      css={{
        width: '100px',
        height: '34px',
        fontSize: '13px',
        fontWeight: 600,
        color: theme.colors.white,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxShadow: '0 4px 10px 0 rgba(0, 0, 0, 0.1)',
      }}
      {...restProps}
    >
      {children}
    </div>
  );
}

export function DescriptionItem({
  children,
  ...restProps
}: {
  children: ReactNode;
  itemProp?: string;
  itemScope?: boolean;
  itemType?: string;
}) {
  return (
    <div
      css={{
        display: 'flex',
        lineHeight: '1.3',
        maxHeight: '32px',
        overflow: 'hidden',
        '&>svg': {
          flex: '0 0 auto',
          marginRight: '5px',
        },
      }}
      {...restProps}
    >
      {children}
    </div>
  );
}
